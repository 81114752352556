<template>
  <post :post="postData"></post>
</template>

<script>
import Post from '../components/Post'
import project1 from '../mock/project_1.json'
import project2 from '../mock/project_2.json'
import project3 from '../mock/project_3.json'
export default {
  name: 'Project',
  components: { Post },
  data: function () {
    return {
      slug: this.$route.params.slug
    }
  },
  computed: {
    postData: function () {
      if (this.slug === 'waterfall_by_crown') {
        return project1
      } else if (this.slug === 'hornsby_baptist_church') {
        return project2
      } else {
        return project3
      }
    }
  }
}
</script>

<style scoped>

</style>
